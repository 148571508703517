.app__contact-form-popup-page{
    position: fixed;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    display: flex;
    z-index: 100;
}

.app__contact-form-popup{
    position: sticky;
    z-index: 110;
    display:flex;
    background-color: red;
}

.app__contact-form-backdrop{
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 90;
    background-color: rgba(0, 45, 62, 0.8);
    backdrop-filter: blur(10px);
}