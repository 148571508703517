.app__experience-wrapper{
    display:flex;
    width: 100%;
    max-width: 1200px;
    position: relative;
    justify-content:flex;
    align-items:center;
    transition: all 0.3s ease-in-out;

    @media screen and (min-width: 768px) and (max-width: 1232px)   {
        padding: 0 3rem;
    }

   
}

.app__experience-image{
    display:flex;
    flex:1;
    height: 350px;
    // animation: imageAnimation 5s ease-in 1;

    
    img{
        height: 100%;
        width: 100%;
        object-fit: cover;

    box-shadow: 0 0 35px rgba(0,0,0,0.1);
    border-radius: 5px;
    
    }
}

@keyframes imageAnimation{
    0%{
        x: 100;
        opacity: 0;
    }
    100%{
        x: 0;
        opacity: 1;
    }
}

.app__experience-content-wrapper{
    display:flex;
    flex:1;
    flex-direction: column;
    align-items:flex-start;
    justify-content: flex-start;
    padding: 1rem;
    margin: 0 0 0 40px;

    background-color: var(--white-color);
    border-radius: 5px;
    box-shadow: 0 0 35px rgba(0,0,0,0.1);

    h2{
        color: var(--gray-color);
        margin-bottom: 1rem;
    }

    svg{
        color: var(--orange-color);
        font-size: 2rem;
    }
}

.app__experience-list-item{
    margin: 0.5rem 0;
    display: flex;
    flex-direction: row;

    p{
        padding-left: 1rem;
        font-size: 1rem;
        color: var(--gray-color);
    }
}

@media screen and (max-width: 768px){
    .app__experience-wrapper{
        padding: 0 1rem;
    }

    .app__experience-card-wrap{
        display: flex;
        flex-direction: column;
    }

    .app__experience-image{
        height: 150px;
        padding: 0 0rem 1rem 0rem;
        img{
            height: 150px;
        }
    }

    .app__experience-content-wrapper{
        margin: 0;
    }
}