.app__quality_policy {
  flex: 1;
  width: 100%;
  flex-direction: column;
  padding: 4rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 450px) {
    padding: 4rem 1rem 2rem;
  }
}

.app__quality_policy-content-wrapper {
  max-width: 1200px;
  width: 100%;
}

.app__quality_policy-page-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 2rem 0 1rem 0;

  h1 {
    color: var(--darkBlue-color);
    letter-spacing: 1px;
    font-weight: lighter;
    font-size: 2rem;
  }
}

.app__quality_policy-page-divider {
  width: 100%;
  height: 1px;
  background-color: var(--lightGray-color);
}

.app__quality_policy-page-section {
  padding: 2rem 0;

  h2 {
    color: var(--lightBlue-color);
    font-size: 1.5rem;
  }

  p {
    margin-top: 1.5rem;
    font-size: 1rem;
    color: var(--gray-color);
  }
}

.app__qualityPolicy-values-wrapper {
  display: flex;
  flex-direction: column;
  color: var(--gray-color);
  margin: 0.5rem 0 0.5rem 1rem;
  gap: 0.5rem;

  .app__qualityPolicy-values-title {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: flex-start;
    justify-content: flex-start;

    svg {
      height: 100%;
      margin-top: 2px;
    }
  }

  .app__qualityPolicy-values-subtitles-wrapper {
    margin-bottom: 1rem;
    .app__quality-values-subtitles {
      display: flex;
      flex-direction: row;
      gap: 4px;
      align-items: flex-start;
      justify-content: flex-start;
      margin-left: 1.5rem;
      margin-bottom: 1rem;
    }

    .app__quality-values-subtitles:last-child {
      margin-bottom: 1rem;
    }
  }
}
