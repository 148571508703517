.app__homepage-services{
    display:flex;
    width: 100%;
    max-width: 1200px;
    position: relative;
    justify-content:flex;
    align-items:center;
    flex-direction: column;
    transition: all 0.3s ease-in-out;
}

.app__homepage-services-title{
   h1{
       color: var(--gray-color);
   } 

   p{
       color: gray;
   }
}

.app__homepage-services-card-wrapper{
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    background-color: var(--white-color);
    border-radius: 5px;
    margin: 1rem 3rem 0 3rem;
    box-shadow: 0 0 35px rgba(0,0,0,0.1);


    @media screen and (max-width: 800px){
        display:flex;
        flex-direction: column;
        margin: 1rem 1rem 0 1rem;
    }

    @media screen and (min-width: 2000px){
        width: 1200px;
    }

    @media screen and (min-width: 1232px){
        margin: 2rem 0 0 0;
    }

   
}

.app__homepage-services-card{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px;
    border: 1px solid rgba(0,0,0,0.05);
    transition: all 0.3s ease-in-out;
    background-color: var(--white-color);
    text-decoration: none;

    img{
        height: 80px;
        width: 80px;
        object-fit: scale-down;
        user-select: none;
        filter: grayScale(1);
    }

    h2{
        color: var(--darkBlue-color);
        transition: all 0.3s ease-in-out;
        padding-left: 0.25rem;
        font-size: 1.3rem;
    }

    p{
        margin-top: 0.5rem;
        color: var(--gray-color);
        font-size:1rem;

    
    }


    &:hover{
        cursor: pointer;
        scale: 1.05;
        border: 1px solid transparent;
        z-index: 1;
        box-shadow: 0 0 25px rgba(255,134,70,0.5);

        img{
        filter: grayScale(0);
        }

        h2{
            color: var(--orange-color);
        }

        p{
            -webkit-line-clamp: 40;
        }
    }

    @media screen and (max-width: 800px){
       &:hover{
           scale: none;
       }
    }
  
  

    
    @media screen and (min-width: 2000px){
       
        img{
            height: 120px;
            width: 120px;
        }
       
    }

    

}

