.app__reworking{
    flex: 1;
    width: 100%;
    min-height: 100vh;
    justify-content: flex-start;
    flex-direction: column;
    padding: 4rem 2rem;
    display: flex;
    align-items: center;
  
    @media screen and (max-width: 450px) {
      padding: 4rem 1rem 2rem;
    }
  }

  .app__reworking-content-wrapper{
    max-width: 1200px;
    width: 100%;
  
  }
  
  .app__reworking-page-title-wrapper{
    display:flex;
    align-items:center;
    justify-content:flex-start;
    width: 100%;
    padding: 2rem 0 1rem 0;
  
    h1{
      color: var(--darkBlue-color);
      letter-spacing:1px;
      font-weight: lighter;
      font-size: 2rem;
    }
  }
  
  .app__reworking-page-divider{
    width: 100%;
    height: 1px;
    background-color: var(--lightGray-color);
  }
  
  .app__reworking-page-section{
    padding: 2rem 0;
  
    h2{
      color: var(--lightBlue-color);
      font-size: 1.5rem;
    }
  
    p{
      margin-top: 1.5rem;
      font-size: 1rem;
      color: var(--gray-color);
    }
  }