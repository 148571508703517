

.app__contact-wrapper-container{
display:flex;
flex-direction: row;

@media screen and (max-width: 900px){
  flex-direction: column;
}
}

.app__contact-form-wrapper{
  display:flex;
  width: 100%;
  max-width: 1200px;
  position: relative;
  justify-content:center;
  align-items:center;
  transition: all 0.3s ease-in-out;
  flex-direction: row;
  
}

#form{
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    padding: 1rem;
    width: 100%;
    border-radius: 5px;
    background: var(--white-color);
    max-width: 850px;
    box-shadow: 0 0 35px rgba(0,0,0,0.1);

}

.app__contact-form-group-wrapper{
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.app__contact-form-group-container{
  display:flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  input{
    width: 100%;
  }

}


label{
    display: flex;
    flex-direction: column;
    // gap: .5rem;
    color: var(--gray-color);
    margin-top: 0.5rem;
}

input, textarea{
    background: var(--darkBlue-color);
    padding: .75rem;
    font-size: 1rem;
    border-radius: .5rem;
    color: #e5e5e5;
    border: 1px solid transparent;
    transition: all .25s ease 0s
  
  }

  textarea{
    width: 100%;
    max-width: 100%;
    max-height: 200px;
    min-width: 200px;
    min-height: 50px;
  }

input:hover,
textarea:hover{
    outline: none !important;
  border-color: var(--lightBlue-color);
  box-shadow: 0 0 10px 3px var(--lightBlue-color);
}


 input:focus,
 textarea:focus{
    outline: none !important;
  border-color: var(--lightBlue-color);
  box-shadow: 0 0 10px 3px var(--lightBlue-color);
}

.app__contact-form-file-upload-wrapper{
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
align-items:center;
justify-content: center;
margin: 1rem 0;
border: 1px dotted var(--gray-color);
border-radius:0.5rem;

h4{
  color: var(--gray-color);
  margin-top: 1rem;
}

p{
  padding: 0.2rem 2rem;
  color: gray;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
}


//file upload logic
.file-upload{
  width: 100%;
  max-width:600px;
  padding: 0 2rem;
}

.file-upload .file-upload-select {
	display: block;
  width: 100%;
	color: #dbdbdb;
	cursor: pointer;
	text-align: left;
	background: var(--darkBlue-color);
	overflow: hidden;
	position: relative;
	border-radius: 0.5rem;
  margin: 1rem 0;

  &:hover{
    outline: none !important;
    border-color: var(--lightBlue-color);
    box-shadow: 0 0 10px 3px var(--lightBlue-color);
  }
}
.file-upload .file-upload-select .file-select-button {
	background: var(--darkBlue-color);
	padding: 10px;
	display: inline-block;
}
.file-upload .file-upload-select .file-select-name {
	display: inline-block;
	padding: 10px;
}
.file-upload .file-upload-select:hover .file-select-button {
	background: #324759;
	color: #ffffff;
	transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
}

.file-select-button{
&:hover{
background:var(--darkBlue-color);
}
}


input[type="file"] {
	display: none;
}

.app__contact-form-submit-btn{
  background: var(--orange-color);
  color: var(--white-color);
  width: 100%;
  max-width:200px;
  border-radius:5px;
  padding: 0.5rem 1rem;
  border: 1px solid var(--orange-color);
  transition: all 0.3s ease-in-out;

  &:hover{
    cursor:pointer;
    background: var(--white-color);
    color: var(--orange-color);
  }
}

.app__contact-form-close-btn{
  background: var(--gray-color);
  color: var(--white-color);
  width: 100%;
  max-width:150px;
  border-radius:5px;
  padding: 0.5rem 1rem;
  border: 1px solid var(--gray-color);
  transition: all 0.3s ease-in-out;

  &:hover{
    cursor:pointer;
    background: var(--white-color);
    color: var(--gray-color);
  }
}
