.app__homepage-header{
    display:flex;
    width: 100%;
    height: calc(100vh - 8rem);
    position: relative;
    justify-content:flex;
    align-items:center;
    flex-direction: column;
    transition: all 0.3s ease-in-out;
    padding:0;
    margin: 0;

 
    
}

.app__homepage-header-wrapper{
    max-width: 1200px;
    display: flex;
    height: 100%;
    width: 100%;
    z-index: 1;
    position: relative;
    display:flex;
    padding:0;
    margin:0;
}


.app__homepage-hero-image{
    display:flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: 0;
}

.app__homepage-fastQuote-card{
    position: absolute;
    z-index: 1;
    right:0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:column;
    padding: 1rem 5rem 3rem;

    @media screen and (max-width: 900px){
        // position: absolute;
        left: 1rem;
        bottom: 1rem;
        right: 1rem;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction:column;
        // width: 100%;
        padding: 0;
    }

   
}

.app__hero-card-content{
    background-color: rgba(0,0,0,0.30);
    border-top: 1px solid var(--lightGray-color);
    border-left: 1px solid var(--lightGray-color);
    border-right: 1px solid var(--lightGray-color);
    backdrop-filter: blur(4px);
    display: flex;
    flex-direction: column;
    border-radius: 5px ;


 
    h2{
        color: var(--white-color);
        padding: 1rem;
        font-size:2rem;
        font-weight: 800;
    }

    @media screen and (max-width: 900px){
        h2{
            padding: 1rem;
            font-size:1.3rem;
            font-weight: 800;
        }
    }
}

.app__header-fastQuote-listItem{
    display: flex;
    flex-direction:row;
    align-items: center;
    padding: 0.5rem 1rem 0 1rem;

    p{
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 1.3rem;
        color: var(--white-color);
        padding: 0 0 0 1rem;
    }


    svg{
        fill: var(--white-color);
        font-size: 2rem;
        
    }

    @media screen and (max-width: 900px){
        p{
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 1rem;
            color: var(--white-color);
            padding: 0 0 0 0.8rem;
        }
    
    
        svg{
            height: 100%;
            fill: var(--white-color);
            font-size: 1.3rem;
            align-items: center;
            justify-content: center
        }
    }
}

.app__header-fastQuote-listItem:nth-last-child(1){
    padding: 0.5rem 1rem 1rem 1rem;
}

.app__hero-btn-wrapper{
    display:flex;
    justify-content:center;
    align-items:center;
    width: 100%;
    padding:1rem;
    background-color:white;
    border-radius: 0 0 5px 5px;
    margin-top: 1rem;

    
}

.app__hero-btn{
    background-color:var(--orange-color);
    padding: 0.5rem 1rem;
    border: 1px solid var(--orange-color);
    border-radius: 2px ;
    color: var(--white-color);
    font-size: 1rem;
    letter-spacing: 0.05rem;
    transition: all 0.3s ease-in-out;
    text-decoration: none;

    &:hover{
        color: var(--orange-color);
        background-color:var(--white-color);
        cursor:pointer;
        border: 1px solid var(--orange-color);
    }
}