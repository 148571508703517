
.app__about-headerIMG{
    height: 150px;
    width: 100%;
    max-width: 1200px;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    position: relative;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @media screen and (min-width: 768px) {
        height: 200px;
    }

}

.app__header-floating-img{
 position: absolute;
 right: 10%;
 bottom: -100px;
 background-color: var(--white-color);
 border-radius: 50%;
 padding: 2rem;
 height: 220px;
 width: 220px;
 box-shadow: 0 0 45px rgba(0,0,0,0.2);
 overflow: hidden;

 img{
    width: 100%;
    height: 100%;
    object-fit: contain;
 }

 @media screen and (max-width: 900px) {
    bottom: 0;
    left: calc(50% - 50px);
    border-radius: 50%;
    padding: 1rem;
    height: 100px;
    width: 100px;
}
}