.app__about{
  flex: 1;
  width: 100%;
  flex-direction: column;
  padding: 4rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 450px) {
    padding: 4rem 1rem 2rem;
  }
}


.app__about-content-wrapper{
  max-width: 1200px;
  width: 100%;

}

.app__about-page-title-wrapper{
  display:flex;
  align-items:center;
  justify-content:flex-start;
  width: 100%;
  padding: 2rem 0 1rem 0;

  h1{
    color: var(--darkBlue-color);
    letter-spacing:1px;
    font-weight: lighter;
    font-size: 2rem;
  }
}

.app__about-page-divider{
  width: 100%;
  height: 1px;
  background-color: var(--lightGray-color);
}

.app__about-page-section{
  padding: 2rem 0;

  h2{
    color: var(--lightBlue-color);
    font-size: 1.5rem;
  }

  p{
    margin-top: 1.5rem;
    font-size: 1rem;
    color: var(--gray-color);
  }
}

.app__about-p-title{
  margin: 1.5rem 0 0 0;
  color: var(--gray-color);
}

.app__about-p-list-item{
  padding: 0 0 0 1rem;
  color: var(--gray-color);

  svg{
    margin-right: 0.2rem;
  }
}

.app__about-logo-wrapper{
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 2rem;
  padding: 2rem;
  transition: all 0.3s ease-in-out;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    gap: 3rem;
  }
}

.app__about-logo-card{
  flex:1;
  display: flex;
  align-items: center;
  justify-content: center;

  img{
    height: 100%;
    width: 100%;
    object-fit: contain;
    max-height: 150px;
    // width: 350px;
    border-radius: 3px;
  }
}

