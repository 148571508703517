.app__general-info{
    flex: 1;
    width: 100%;
    flex-direction: column;
    padding: 4rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  
    @media screen and (max-width: 450px) {
      padding: 4rem 1rem 2rem;
    }
  }

  .app__general-info-content-wrapper{
        max-width: 1200px;
        width: 100%;     
  }

  .app__general-section-wrapper{
    padding: 2rem 0;

    h2{
      color: var(--lightBlue-color);
      font-size: 1.5rem;
    }
  
    p{
      margin-top: 1.5rem;
      font-size: 1rem;
      color: var(--gray-color);
    }
  }

  .app__general-info-tolerance-list-item{
        color: var(--gray-color);
        margin-bottom: 0.5rem;
  }

  .app__general-info-list-wrapper{
      margin-top: 1.5rem;
  }

  .app__general-info-list-wrapper-styled{
    margin-top: 1.5rem; 
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(200px, 1fr) );
    grid-row-gap: 1.5rem;
    grid-column-gap: 1.5rem;
    transition: all 0.3s ease-in-out;
  }

  .app__general-info-tolerance-list-item-styled{
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    // justify-content: space-between;

    svg{
        font-size: 1.5rem;
        // position: absolute;
        color: var(--orange-color);
    }

    h6{
        width: 100%;
        display: flex;
        align-items: center;
        font-size: 1rem;
        color: var(--gray-color);
        padding-left: 1.5rem;
        font-weight: lighter;
    }

  }

  .app__general-info-list-item-svg-wrapper{
    // position: absolute;
    left: 1rem;
  }
  