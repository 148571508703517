.app__get-quotation-page{
    flex: 1;
    width: 100%;
    flex-direction: column;
    padding: 4rem 2rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    justify-content: flex-start;
  
    @media screen and (max-width: 900px) {
      padding: 4rem 1rem;
    }

   
}